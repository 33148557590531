import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconsService {
    /**
     * Constructor
     */
    constructor() {
        const domSanitizer = inject(DomSanitizer);
        const matIconRegistry = inject(MatIconRegistry);

        const iconsPath = 'icons/';
        const iconsPersonalizedPath = 'icons/personalized/';
        const imagesPath = 'images/';

        matIconRegistry
            .addSvgIcon(
                'id',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPersonalizedPath.concat('id.svg')
                )
            )
            .addSvgIcon(
                'created-calendar',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPersonalizedPath.concat('created-calendar.svg')
                )
            )
            .addSvgIcon(
                'school-grade',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPersonalizedPath.concat('school-grade.svg')
                )
            )
            .addSvgIcon(
                'subject',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPersonalizedPath.concat('subject.svg')
                )
            )
            .addSvgIcon(
                'world-web',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPersonalizedPath.concat('world-web.svg')
                )
            )
            .addSvgIcon(
                'question-and-answer',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPersonalizedPath.concat('question-and-answer.svg')
                )
            )
            .addSvgIcon(
                'account-circle',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPersonalizedPath.concat('account-circle.svg')
                )
            )
            .addSvgIcon(
                'logout',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPersonalizedPath.concat('logout.svg')
                )
            )
            .addSvgIcon(
                'visibility',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPersonalizedPath.concat('visibility.svg')
                )
            )
            .addSvgIcon(
                'toggle',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPersonalizedPath.concat('toggle.svg')
                )
            )
            // TODO: Add namespace to overwrite existing icons
            .addSvgIconSet(
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPath.concat('material-twotone.svg')
                )
            )
            .addSvgIcon(
                'delete',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    imagesPath.concat('delete.svg')
                )
            )
            .addSvgIcon(
                'edit',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    imagesPath.concat('edit.svg')
                )
            )
            .addSvgIcon(
                'edit-square',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    imagesPath.concat('edit.svg')
                )
            )
            //FUSE Icons
            .addSvgIconSetInNamespace(
                'mat_outline',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPath.concat('material-outline.svg')
                )
            )
            .addSvgIconSetInNamespace(
                'mat_solid',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPath.concat('material-solid.svg')
                )
            )
            .addSvgIconSetInNamespace(
                'feather',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPath.concat('feather.svg')
                )
            )
            .addSvgIconSetInNamespace(
                'heroicons_outline',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPath.concat('heroicons-outline.svg')
                )
            )
            .addSvgIconSetInNamespace(
                'heroicons_solid',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPath.concat('heroicons-solid.svg')
                )
            )
            .addSvgIconSetInNamespace(
                'heroicons_mini',
                domSanitizer.bypassSecurityTrustResourceUrl(
                    iconsPath.concat('heroicons-mini.svg')
                )
            );

        // .addSvgIconInNamespace('assets', 'id', domSanitizer.
        // bypassSecurityTrustResourceUrl('assets/icons/id.svg'))
    }
}
