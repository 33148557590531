import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import {
    LabelColorConfig,
    LabelColorConstant,
} from '../../constants/label-color.constant';
import { LabelColors } from '../../enums/label-colors.enum';

export interface LabelChipConfig {
    color: LabelColors;
    label: string;
}

@Component({
    selector: 'app-status-color-chip',
    standalone: true,
    imports: [MatIcon, NgClass],
    templateUrl: './status-color-chip.component.html',
    styleUrl: './status-color-chip.component.scss',
})
export class StatusColorChipComponent implements OnInit {
    @Input({ required: true }) config!: LabelChipConfig;

    labelColorConfig: LabelColorConfig = LabelColorConstant[LabelColors.GREEN];

    ngOnInit() {
        this.labelColorConfig = LabelColorConstant[this.config.color];
    }
}
