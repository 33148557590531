export enum UserRole {
    OPERATOR = 'operator',
    COORDINATOR = 'coordinator',
    ADMIN = 'admin',
    VISITOR = 'visitor',
}

export function getUserRole(role: string): UserRole {
    const roleValues = Object.values(UserRole) as string[];
    return roleValues.includes(role) ? (role as UserRole) : UserRole.VISITOR;
}
