import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from './data';
import { FuseNavigationItem } from '../../../@fuse/components/navigation';
import { cloneDeep } from 'lodash-es';
import { UserType } from '../../shared/enums/user-type.enum';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(role: UserType): Observable<Navigation> {

        // return this._httpClient.get<Navigation>('api/common/navigation').pipe(
        //     tap((navigation) => {
        //         this._navigation.next(navigation);
        //     })
        // );

        // TODO: No colocar en minuscular
        const roleLowerCase = role.toLowerCase();
        // Fill compact navigation children using the default navigation
        const compactNavigationFilter = this._compactNavigation
            .filter((horizontalNavigation) => {
                const allowedRoles = horizontalNavigation.roles ?? [];
                return allowedRoles.includes(roleLowerCase);
            });
        compactNavigationFilter.forEach((compactNavItem) => {
            this._defaultNavigation.forEach((defaultNavItem) => {
                if (defaultNavItem.id === compactNavItem.id) {
                    compactNavItem.children = cloneDeep(defaultNavItem.children)?.filter((navigation) => {
                        const allowedRoles = navigation.roles ?? [];
                        return allowedRoles.includes(roleLowerCase);
                    });
                }
            });
        });

        // Fill futuristic navigation children using the default navigation
        this._futuristicNavigation.forEach((futuristicNavItem) => {
            this._defaultNavigation.forEach((defaultNavItem) => {
                if (defaultNavItem.id === futuristicNavItem.id) {
                    futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        // Fill horizontal navigation children using the default navigation
        this._horizontalNavigation.filter((horizontalNavigation) => {
            const allowedRoles = horizontalNavigation.roles ?? [];
            return allowedRoles.includes(role);
        }).forEach((horizontalNavItem) => {
            this._defaultNavigation.forEach((defaultNavItem) => {
                if (defaultNavItem.id === horizontalNavItem.id) {
                    horizontalNavItem.children = cloneDeep(defaultNavItem.children)?.filter((navigation) => {
                        const allowedRoles = navigation.roles ?? [];
                        return allowedRoles.includes(role);
                    });

                }
            });
        });

        const navigationData = {
            compact: cloneDeep(compactNavigationFilter),
            default: cloneDeep(this._defaultNavigation),
            futuristic: cloneDeep(this._futuristicNavigation),
            horizontal: cloneDeep(this._horizontalNavigation)
        }

        this._navigation.next(navigationData);

        return of(navigationData);
    }
}
