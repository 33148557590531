import { HttpInterceptorFn } from '@angular/common/http';
import { getAuth } from '@angular/fire/auth';
import { from, switchMap } from 'rxjs';

export const firebaseTokenInterceptor: HttpInterceptorFn = (req, next) => {
    console.log('firebaseTokenInterceptor');
    try {
        const auth = getAuth();
        const user = auth.currentUser;

        console.log('user', user);
        if (!user) return next(req);

        return from(user.getIdToken()).pipe(
            switchMap((token) => {
                const cloned = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                return next(cloned);
            })
        );
    } catch (e) {
        console.log('firebaseTokenInterceptor error', e);
        return next(req);
    }
};
