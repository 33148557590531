export enum LabelColors {
    GRAY = 'gray',
    RED = 'red',
    ORANGE = 'orange',
    YELLOW = 'yellow',
    GREEN = 'green',
    TEAL = 'teal',
    BLUE = 'blue',
    INDIGO = 'indigo',
    PURPLE = 'purple',
    PINK = 'pink',
}
