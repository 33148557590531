import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Functions } from '@angular/fire/functions';
import { BaseFunctionRequestDS } from '@app/core/infrastructure/datasources';
import { GeneralStatisticsResponseDto } from '@app/modules/statistics/infrastructure';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StatisticDSService extends BaseFunctionRequestDS {
    override endpointBasePath = 'statistics';

    constructor(_functions: Functions, _httpClient: HttpClient) {
        super(_functions, _httpClient);
    }

    public getByUserAndRole(): Observable<GeneralStatisticsResponseDto> {
        return this.get<GeneralStatisticsResponseDto>('');
    }
}
