import { Injectable } from '@angular/core';
import {
    Auth,
    AuthErrorCodes,
    authState,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    User,
} from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { UserType } from '@app/shared/enums/user-type.enum';
import { IFireUser } from '@app/shared/interfaces/auth.interfaces';
import { FirebaseError } from '@firebase/util';
import { BehaviorSubject, map, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _authenticated: boolean = false;
    userData = new BehaviorSubject<IFireUser | undefined>(undefined);

    constructor(
        private firestore: Firestore,
        private auth: Auth,
        private _router: Router,
        private _activatedRoute: ActivatedRoute
    ) {
        this.subscribeToAuth();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('authenticated', token ?? 'true');
    }

    get accessToken(): string {
        return localStorage.getItem('authenticated') ?? '';
    }

    subscribeToAuth(): void {
        authState(this.auth)
            .pipe(
                map(async (user) => {
                    if (user) await this.setNextUserData(user);
                    else this.cleanUserData();
                })
            )
            .subscribe();
    }

    private async setNextUserData(user: User): Promise<void> {
        const { email = '', uid, emailVerified, photoURL, displayName } = user;
        const token = await user.getIdTokenResult();
        const { role = UserType.Visitor, institute } = token.claims;
        console.log(role);
        const userFor: IFireUser = {
            email: String(email),
            uid,
            emailVerified,
            photoURL,
            displayName,
            role: role as UserType,
            instituteId: institute ? String(institute) : null,
        };
        this.userData.next(userFor);
        this._authenticated = true;
        this.accessToken = 'true';
        if (!['signIn', '/signIn'].includes(this._router.url)) return;
        // Set the redirect url.
        // The '/signed-in-redirect' is a dummy url to catch the request and redirect the user
        // to the correct page after a successful sign in. This way, that url can be set via
        // routing file and we don't have to touch here.
        const redirectURL =
            this._activatedRoute.snapshot.queryParamMap.get('redirectURL') ||
            '/signed-in-redirect';
        // Navigate to the redirect url
        await this._router
            .navigateByUrl(redirectURL)
            .catch((e) => console.error(e));
        // if (this.router.url === '/auth/signIn') {
        //     let path = 'app/books';
        //     if (role !== UserType[UserType.Root]) path = 'app/users';
        //     console.log(path);
        //     await this.router.navigate([path]).catch(e => console.error(e));
        // }
    }

    private cleanUserData(): void {
        this.userData.next(undefined);
        this._authenticated = false;
        localStorage.removeItem('authenticated');
    }

    async signInWithEmail(email: string, password: string): Promise<void> {
        try {
            await signInWithEmailAndPassword(this.auth, email, password);
        } catch (err) {
            let message = 'Error desconocido.';
            if (err instanceof FirebaseError) {
                switch (err.code) {
                    case AuthErrorCodes.USER_DELETED:
                    case AuthErrorCodes.INVALID_PASSWORD:
                        message = 'Usuario o contraseña inválidos';
                        break;
                    case AuthErrorCodes.USER_DISABLED:
                        message =
                            'Usuario desactivado, si crees que es un error contactate con soporte.';
                        break;
                    default:
                        message = 'Error de Fire desconocido.';
                }
            } else {
                console.error(err);
            }
            throw Error(message);
        }
    }

    async signOut(): Promise<void> {
        try {
            // Remove the access token from the local storage
            localStorage.removeItem('authenticated');

            // Set the authenticated flag to false
            this._authenticated = false;

            await this.auth.signOut();

            this._router.navigate(['/']).catch((e) => console.error(e));
        } catch (e) {
            console.error(e);
        }
    }

    async recoveryPassword(email: string): Promise<void> {
        try {
            await sendPasswordResetEmail(this.auth, email);
        } catch (e) {
            console.error(e);
        }
    }

    check(): Observable<boolean> {
        if (this._authenticated) return of(true);
        return of(this.accessToken !== '');
    }
}
