import { Routes } from '@angular/router';

import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/no-auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { initialDataResolver } from './app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Routes = [
    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'dashboards/app' },
    // Redirect signed-in user to the '/dashboards/project'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'dashboards/app',
    },
    {
        path: 'visitors',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: { layout: 'empty' },
        loadChildren: () =>
            import('app/modules/visitors/visitors-routing.module').then(
                (m) => m.VisitorsRoutingModule
            ),
    },

    {
        path: '',
        canMatch: [NoAuthGuard],
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: { layout: 'empty' },
        loadChildren: () =>
            import('app/modules/auth/auth-routing.module').then(
                (m) => m.AuthRoutingModule
            ),
    },
    {
        path: '',
        canMatch: [AuthGuard],
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialQueries: initialDataResolver,
        },
        children: [
            {
                path: 'dashboards',
                loadChildren: () =>
                    import(
                        './modules/dashboards/dashboards-routing.module'
                    ).then((m) => m.DashboardsRoutingModule),
            },
            {
                path: 'certificates',
                loadChildren: () =>
                    import('./modules/certificate/certificate.routes').then(
                        (m) => m.CERTIFICATE_ROUTES
                    ),
            },
            {
                path: 'vaccinations',
                loadChildren: () =>
                    import('./modules/vaccination/vaccination.routes').then(
                        (m) => m.VACCINATION_ROUTES
                    ),
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('./modules/users/user.routes').then(
                        (m) => m.USER_ROUTES
                    ),
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import('./modules/settings/settings.module').then(
                        (m) => m.SettingsModule
                    ),
            },
        ],
    },
];
