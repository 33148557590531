export enum CertificateFileStateEnum {
    PROCESSING = 'processing',
    ERROR = 'error',
    DONE = 'done',
    UNKNOWN = 'unknown',
}

export function GetCertificateFileStateEnumByValue(
    value: string
): CertificateFileStateEnum {
    return (
        Object.values(CertificateFileStateEnum).find(
            (state) => state === value
        ) ?? CertificateFileStateEnum.UNKNOWN
    );
}
