import { LabelColors } from '../enums/label-colors.enum';

export interface LabelColorConfig {
    text: string;
    bg: string;
    combined: string;
}

export const LabelColorConstant: Record<LabelColors, LabelColorConfig> = {
    [LabelColors.GRAY]: {
        text: 'text-gray-500',
        bg: 'bg-gray-500',
        combined: 'text-gray-800 bg-gray-100',
    },
    [LabelColors.RED]: {
        text: 'text-red-500',
        bg: 'bg-red-500',
        combined: 'text-red-800 bg-red-100',
    },
    [LabelColors.ORANGE]: {
        text: 'text-orange-500',
        bg: 'bg-orange-500',
        combined: 'text-orange-800 bg-orange-100',
    },
    [LabelColors.YELLOW]: {
        text: 'text-yellow-500',
        bg: 'bg-yellow-500',
        combined: 'text-yellow-800 bg-yellow-100',
    },
    [LabelColors.GREEN]: {
        text: 'text-green-500',
        bg: 'bg-green-500',
        combined: 'text-green-800 bg-green-100',
    },
    [LabelColors.TEAL]: {
        text: 'text-teal-500',
        bg: 'bg-teal-500',
        combined: 'text-teal-800 bg-teal-100',
    },
    [LabelColors.BLUE]: {
        text: 'text-blue-500',
        bg: 'bg-blue-500',
        combined: 'text-blue-800 bg-blue-100',
    },
    [LabelColors.INDIGO]: {
        text: 'text-indigo-500',
        bg: 'bg-indigo-500',
        combined: 'text-indigo-800 bg-indigo-100',
    },
    [LabelColors.PURPLE]: {
        text: 'text-purple-500',
        bg: 'bg-purple-500',
        combined: 'text-purple-800 bg-purple-100',
    },
    [LabelColors.PINK]: {
        text: 'text-pink-500',
        bg: 'bg-pink-500',
        combined: 'text-pink-800 bg-pink-100',
    },
};
