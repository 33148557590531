import { HttpClient } from '@angular/common/http';
import {
    Functions,
    httpsCallable,
    httpsCallableData,
} from '@angular/fire/functions';
import { from, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export class BaseFunctionRequestDS {
    protected endpointBasePath = 'basePath';

    constructor(
        protected readonly _functions: Functions,
        protected readonly _httpClient: HttpClient
    ) {}

    protected callFunction<T>(
        functionName: string,
        data?: Record<string, unknown>
    ): Observable<T> {
        if (!data)
            return from(httpsCallable(this._functions, functionName)()).pipe(
                map((res) => res.data as T)
            );
        const callable = httpsCallableData(this._functions, functionName);
        return callable(data) as Observable<T>;
    }

    get<T>(path: string): Observable<T> {
        return this._httpClient.get<T>(this.getCompletePath(path));
    }

    post<T>(path: string, data: unknown): Observable<T> {
        return this._httpClient.post<T>(this.getCompletePath(path), data);
    }

    put<T>(path: string, data: unknown): Observable<T> {
        return this._httpClient.put<T>(this.getCompletePath(path), data);
    }

    delete<T>(path: string): Observable<T> {
        return this._httpClient.delete<T>(this.getCompletePath(path));
    }

    private getCompletePath(path: string): string {
        return `${environment.baseAPI}/${this.endpointBasePath}/${path}`;
    }
}
