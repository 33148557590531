import { UserRole } from '@app/modules/users/enums';
import { LabelColors } from '@app/shared/enums';
import { LabelAndColorConfig } from '@app/shared/interfaces';

export const ConfigUserRolesConstant: Record<UserRole, LabelAndColorConfig> = {
    [UserRole.ADMIN]: {
        label: 'Administrador',
        color: LabelColors.PURPLE,
    },
    [UserRole.COORDINATOR]: {
        label: 'Coordinador',
        color: LabelColors.BLUE,
    },
    [UserRole.OPERATOR]: {
        label: 'Operador',
        color: LabelColors.GREEN,
    },
    [UserRole.VISITOR]: {
        label: 'Visitante',
        color: LabelColors.GRAY,
    },
};

export const GetUserRoleConfig = (role: UserRole): LabelAndColorConfig => {
    return (
        ConfigUserRolesConstant[role] ?? {
            label: 'Desconocido',
            color: LabelColors.GRAY,
        }
    );
};
