import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Route,
    RouterStateSnapshot,
    UrlSegment,
} from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NoAuthGuard {
    constructor(private _authService: AuthService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    canMatch(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @private
     */
    private _check(): Observable<boolean> {
        // Check the authentication status and return an observable of
        // "true" or "false" to allow or prevent the access
        return this._authService.check().pipe(
            switchMap((authenticated) => {
                return of(!authenticated);
            })
        );
    }
}

// export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
//     const router: Router = inject(Router);
//     console.log('NoAuthGuard');
//
//     // Check the authentication status
//     return inject(AuthService).check().pipe(
//         switchMap((authenticated) => {
//             // If the user is authenticated...
//             if (authenticated) {
//                 console.log('authenticated');
//                 console.log(router.parseUrl('dashboards/app'));
//                 return of(router.parseUrl('dashboards/app'));
//                 // router.navigate(['dashboards/app']);
//             }
//             return of(!authenticated);
//
//             // Allow the access
//             return of(true);
//         }),
//     );
// };
