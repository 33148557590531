import { FileStoragePathEnum } from '@app/shared/enums';

export const FileStoragePathsConstant: Record<FileStoragePathEnum, string> = {
    [FileStoragePathEnum.CERTIFICATE]: 'certificate/',
    [FileStoragePathEnum.EXAMPLE_DOCUMENTS]: 'exampleDocuments/',
};

export function GetFileStoragePath(path: FileStoragePathEnum): string {
    return FileStoragePathsConstant[path] ?? 'unknown-path/';
}
