import { LabelColors } from '../enums';
import { VaccinationStatusEnum } from '../enums/vaccination-status.enum';
import { LabelAndColorConfig } from '../interfaces';

export const VaccinationStatusConstant: Record<
    VaccinationStatusEnum,
    LabelAndColorConfig
> = {
    [VaccinationStatusEnum.PENDING]: {
        label: 'Pendiente',
        color: LabelColors.ORANGE,
    },
    [VaccinationStatusEnum.FINISHED_SUCCESS]: {
        label: 'Finalizado',
        color: LabelColors.GREEN,
    },
    [VaccinationStatusEnum.FINISHED_FAILURE]: {
        label: 'Finalizado',
        color: LabelColors.RED,
    },
    [VaccinationStatusEnum.REPROGRAMMED]: {
        label: 'Reprogramado',
        color: LabelColors.PURPLE,
    },
    [VaccinationStatusEnum.ASSIGNED]: {
        label: 'Asignado',
        color: LabelColors.BLUE,
    },
};

export const GetVaccinationStatusConfig = (
    status: VaccinationStatusEnum
): LabelAndColorConfig => {
    return (
        VaccinationStatusConstant[status] ?? {
            label: 'Desconocido',
            color: LabelColors.GRAY,
        }
    );
};
