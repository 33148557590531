export enum UserType {
    Root = 'root',
    Admin = 'admin',
    Visitor = 'visitor',
    Coordinator = 'coordinator',
    Operator = 'operator',
}

// export function UserTypeFromString(role: string): UserType {
//   switch (role) {
//     case UserType.Root: return UserType.Root;
//     case UserType.Admin: return UserType.Admin;
//     case UserType.Teacher: return UserType.Teacher;
//     case UserType.Student: return UserType.Student;
//     case UserType.Parent: return UserType.Parent;
//     default: return UserType.Visitor;
//   }
// }
