import { CertificateFileStateEnum, LabelColors } from '../enums';
import { LabelAndColorConfig } from '../interfaces';

export const CertificateFileConfigStatesConstant: Record<
    CertificateFileStateEnum,
    LabelAndColorConfig
> = {
    [CertificateFileStateEnum.DONE]: {
        label: 'Importado',
        color: LabelColors.GREEN,
    },
    [CertificateFileStateEnum.ERROR]: {
        label: 'Error',
        color: LabelColors.RED,
    },
    [CertificateFileStateEnum.PROCESSING]: {
        label: 'Procesando',
        color: LabelColors.ORANGE,
    },
    [CertificateFileStateEnum.UNKNOWN]: {
        label: 'Desconocido',
        color: LabelColors.GRAY,
    },
};

export const GetCertificateFileConfigState = (
    status: CertificateFileStateEnum
): LabelAndColorConfig => {
    return (
        CertificateFileConfigStatesConstant[status] ?? {
            label: 'Desconocido',
            color: LabelColors.GRAY,
        }
    );
};
