export enum Province {
    AZUAY = 'Azuay',
    BOLIVAR = 'Bolívar',
    CANAR = 'Cañar',
    CARCHI = 'Carchi',
    CHIMBORAZO = 'Chimborazo',
    COTOPAXI = 'Cotopaxi',
    EL_ORO = 'El Oro',
    ESMERALDAS = 'Esmeraldas',
    GALAPAGOS = 'Galápagos',
    GUAYAS = 'Guayas',
    IMBABURA = 'Imbabura',
    LOJA = 'Loja',
    LOS_RIOS = 'Los Ríos',
    MANABI = 'Manabí',
    MORONA_SANTIAGO = 'Morona Santiago',
    NAPO = 'Napo',
    ORELLANA = 'Orellana',
    PASTAZA = 'Pastaza',
    PICHINCHA = 'Pichincha',
    SANTA_ELENA = 'Santa Elena',
    SANTO_DOMINGO_DE_LOS_TSACHILAS = 'Santo Domingo de los Tsáchilas',
    SUCUMBIOS = 'Sucumbíos',
    TUNGURAHUA = 'Tungurahua',
    ZAMORA_CHINCHIPE = 'Zamora Chinchipe',
    UNKNOWN = 'Unknown',
    ALL = 'all',
}

export function getProvince(province: string): Province {
    const provinceValues = Object.values(Province) as string[];
    return provinceValues.includes(province)
        ? (province as Province)
        : Province.UNKNOWN;
}
