export class BaseResponseDto<T> {
    success: boolean;
    message: string;
    code: string;
    data: T;

    constructor({ success, message, data, code }: BaseResponseDto<T>) {
        this.success = success;
        this.message = message;
        this.data = data;
        this.code = code;
    }
}
