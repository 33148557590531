
import {  Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(private _authService: AuthService, private _router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can match
     *
     * @param route
     * @param segments
     */
    canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check(segments);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param segments
     * @private
     */
    private _check(segments: UrlSegment[]): Observable<boolean | UrlTree> {
        // Check the authentication status and return an observable of
        // "true" or "false" to allow or prevent the access
        return this._authService.check().pipe(
            switchMap((authenticated) => {
                // If the user is not authenticated...
                if (!authenticated) {
                    // Redirect to the sign-in page with a redirectUrl param
                    const redirectURL = `/${segments.join('/')}`;
                    const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);

                    return of(urlTree);
                }

                // Allow the access
                return of(true);
            })
        );
    }
}


// export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
//     const router: Router = inject(Router);
//     console.log('authenticated');
//     // Check the authentication status
//     return inject(AuthService).check().pipe(
//         switchMap((authenticated) => {
//
//             // If the user is not authenticated...
//             if (!authenticated) {
//                 // Redirect to the sign-in page with a redirectUrl param
//                 const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
//                 const urlTree = router.parseUrl(`sign-in?${redirectURL}`);
//
//                 return of(urlTree);
//             }
//
//             // Allow the access
//             return of(!!authenticated);
//         }),
//     );
// };
