import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from '@app/modules/users/enums';
import {
    GetCertificateFileConfigState,
    GetUserRoleConfig,
    GetVaccinationStatusConfig,
} from '../constants';
import { CertificateFileStateEnum, LabelColors } from '../enums';
import { VaccinationStatusEnum } from '../enums/vaccination-status.enum';
import { LabelAndColorConfig } from '../interfaces';

@Pipe({
    name: 'statusToChipConfig',
    standalone: true,
})
export class StatusToChipConfigPipe implements PipeTransform {
    transform(
        status: number | string,
        target: 'vaccination' | 'certificate-file' | 'role'
    ): LabelAndColorConfig {
        switch (target) {
            case 'vaccination':
                return GetVaccinationStatusConfig(
                    status as VaccinationStatusEnum
                );
            case 'certificate-file':
                return GetCertificateFileConfigState(
                    status as CertificateFileStateEnum
                );

            case 'role':
                return GetUserRoleConfig(status as UserRole);
            default:
                return {
                    label: 'Desconocido',
                    color: LabelColors.GRAY,
                };
        }
    }
}
