import {
    ExampleDocumentTypeEnum,
    FileStoragePathEnum,
} from '@app/shared/enums';

export const ExampleDocumentTypes: Record<
    ExampleDocumentTypeEnum,
    {
        path: FileStoragePathEnum;
        document: string;
    }
> = {
    [ExampleDocumentTypeEnum.EXAMPLE_DOCUMENTS]: {
        document: 'ejemplo-importar-certificados-ppc.csv',
        path: FileStoragePathEnum.EXAMPLE_DOCUMENTS,
    },
};

export function GetExampleDocumentType(type: ExampleDocumentTypeEnum): {
    path: FileStoragePathEnum;
    document: string;
} {
    return ExampleDocumentTypes[type];
}
